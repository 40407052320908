<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Manage Consultants
      </ion-title>
      <ion-buttons slot="end">
                <ion-button @click="closeModal()"> <ion-icon class="request-icon" :icon="closeOutline"></ion-icon></ion-button>
              </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
      <!-- DESKTOP -->
     <ion-grid>
       
          <template  v-for="(consultant, key) in consultantsByExhibitor" :key="key">
            <template v-if="!consultant.disabled || consultant.disabled == null">
              <div style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin-bottom: 15px !important;"> 
         <ion-row >
            
            <ion-col class="modal-consultants-text">
              <div>{{consultant.firstname}} {{consultant.lastname}}</div> 
            </ion-col>
            <ion-col class="modal-consultants-text">
              <div >{{consultant.email}}</div>
            </ion-col>
          </ion-row>
          <ion-row >
            <ion-col class="modal-consultants-text">
              <div>From: <input type="time" required v-model="consultant.available_from" ></div>
            </ion-col>
            <ion-col class="modal-consultants-text">
              <div> Until: <input type="time" required  v-model="consultant.available_until"  ></div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="modal-consultants-text">
              <div> Session length: <input type="number" min="00" max="8" required  v-model="consultant.hour_duration"> : <input type="number" min="00" max="59" required  v-model="consultant.minute_duration"> </div>
            </ion-col>

            </ion-row>
            <ion-row>
            <ion-col class="modal-consultants-text">
              <div>
                    <button class="requests-buttons requests-accept-button request-button"  @click="changeConsultantTimeBracket(consultant)">
                        <ion-icon :icon="saveOutline"> </ion-icon>
                    </button>
              </div>
            </ion-col>
            <ion-col class="modal-consultants-text">
              <div>
                  <button class="requests-buttons request-button" @click="deleteConsultant(consultant)">
                          <ion-icon  :icon="trashOutline"> </ion-icon>
                    </button>
              </div>
            </ion-col>

            </ion-row>

       </div>
           
          </template>
        </template>

      <div style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin-bottom: 15px !important;">
          <template v-for="(consultant, key) in consultantsByExhibitor" :key="key">
            <template  v-if="consultant.disabled">
         <ion-row class="parent-row" >
              <div class="disabled-tag"> <ion-icon class="disabled-icon" :icon="informationCircleOutline"></ion-icon> <span class="disabled-icon"> DISABLED </span> </div>

            <ion-col style="vertical-align: middle" class="disabled-consultants-text">
              <div >{{consultant.firstname}} {{consultant.lastname}}</div>
            </ion-col>
            <ion-col class="disabled-consultants-text">
              <div>{{consultant.email}}</div>
            </ion-col>
            </ion-row>
             <ion-row >
            <ion-col class="disabled-consultants-text">
              <div>From: {{consultant.available_from}} </div>
            </ion-col>
            <ion-col class="disabled-consultants-text">
              <div>Until: {{consultant.available_until}}</div>
            </ion-col>
             </ion-row>
             <ion-row>
            <ion-col class="disabled-consultants-text">
              <div>Session length: {{formatHour(consultant.hour_duration)}} : {{formatHour(consultant.minute_duration)}}</div>
            </ion-col>
             </ion-row>
             <ion-row>
            <ion-col class="disabled-consultants-text">
              <div>
                <button class="requests-buttons requests-accept-button request-button" @click="addConsultant(consultant)">
                          <ion-icon :icon="addOutline"> </ion-icon>
                </button>
                
                
              </div>
            </ion-col>
         </ion-row>

            </template>

            </template>
         </div>
      </ion-grid>

     
      <ion-grid>
          <ion-row  style="text-align: center" class="modal-request-titles">
              <h1> Add new Consultant </h1>
          </ion-row>

         <ion-row>
            <ion-col class="modal-consultants-text">
               <input type="text" placeholder="Enter Name" v-model="newConsultant.firstName">
            </ion-col>
            <ion-col class="modal-consultants-text">
                <input type="text" placeholder="Enter Lastname" v-model="newConsultant.lastName">
            </ion-col>
            <ion-col class="modal-consultants-text">
               <input type="text" placeholder="@documedias.com" v-model="newConsultant.emailBusiness">
            </ion-col>
            <ion-col class="modal-consultants-text">
              <input type="password"  placeholder="*******" v-model="newConsultant.password">
            </ion-col>
            <ion-col class="modal-consultants-text">
              

              <button class="create-consultant-button" @click="save()">
                  Create Consultant 
              </button>

            </ion-col>
         </ion-row>

         <ion-row v-if="desktop">
              <div class="tooltip"> <ion-icon :icon="helpCircleOutline">   </ion-icon> How does this work? 
              <span class="tooltiptext">To add a new Consultant, a DocumediaS user must be created first.<br> If there is no user with the credentials you have entered, our system will create an account.</span>
              </div> 
          </ion-row>
          <ion-row v-if="!desktop">
              <div class="tooltip"> <ion-icon :icon="helpCircleOutline">   </ion-icon> How does this work? <br> 
              <span>To add a new Consultant, a DocumediaS user must be created first.<br> If there is no user with the credentials you have entered, our system will create an account.</span>
              </div> 
          </ion-row>
      </ion-grid>

    
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
const config = require('@/config');
const API_URL = config.appointment_api_url;
const VUE_APP_API_ACCESS_KEY = config.vue_app_access_key;
import {closeOutline, saveOutline, trashOutline, addOutline, helpCircleOutline, informationCircleOutline} from 'ionicons/icons';
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: "ConsultantsModal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar },
  props: ['requestSent'],
  data(){
    return{
    appointmentsInfo: null,
    requestsInfo: null,
    desktop: null,
    conferenceId : config.conference,
    newConsultant: {},
    newConsultantMap: {},
     authUser: {},
    }
  },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };

    return {closeModal, closeOutline, saveOutline, trashOutline, addOutline, helpCircleOutline, informationCircleOutline  };
  },
  computed: {
    ...mapGetters('appointment',['appointmentsByRequester', 'appointments', 'appointmentRequests']),
 ...mapGetters('consultant',['consultantsByExhibitor', 'mailConsultants' ]),
 },

  methods: {
    ...mapActions('consultant',['getConsultantsByExhibitor', 'getConsultantsByEmail']),
    ...mapActions('appointment',['getAppointments', 'getRequests']),
    ...mapActions('exhibitors', ['getAllExhibitors']),

    async changeConsultantTimeBracket(consultantData){
        await axios.put(API_URL+"consultants/"+ consultantData.id, consultantData);
        await this.getConsultantsByExhibitor(this.userExhibitor[0].id);
     },

    async deleteConsultant(consultant){
        let proceed = confirm("Do you want to remove the consultant "+consultant.firstname+" "+ consultant.lastname+"? All their appointments will be discarded");
        if (proceed){
            consultant.disabled=true;
            await axios.put(API_URL+"consultants/"+ consultant.id, consultant);
            await this.getConsultantsByExhibitor(this.userExhibitor[0].id);
            this.refreshAppointments();
        }
    },

    formatHour(hour){
        return (hour < 10) ? ("0" + hour) : hour;
    },

    async addConsultant(consultant){
        let proceed = confirm("Do you want to add the consultant "+consultant.firstname+" "+ consultant.lastname+"?");
            if (proceed){
                consultant.disabled=false;
                await axios.put(API_URL+"consultants/"+ consultant.id, consultant);
                await this.getConsultantsByExhibitor(this.userExhibitor[0].id);
                this.refreshAppointments();
            }
    },

    async refreshAppointments(){
       await this.getAppointments(this.appointmentsInfo)
      await this.getRequests(this.requestsInfo)
      this.$emit('updatedConsultants');
    },

    async save(){
    if (
        this.newConsultant.firstName == null ||
        this.newConsultant.lastName == null ||
        this.newConsultant.emailBusiness == null || !this.newConsultant.emailBusiness.includes('@documedias.com') ||
        this.newConsultant.password == null
      ) {
        alert("Please fill all the fields and check the format");
      } else {
        await this.getConsultantsByEmail(this.newConsultant.emailBusiness)
        if(this.mailConsultants.length>0){
          alert("An user with this email already exists");
        } else {
        this.newConsultant.identifier = this.newConsultant.emailBusiness;
        
        let headers = {'api-access-key': VUE_APP_API_ACCESS_KEY,'x-api-key': VUE_APP_API_ACCESS_KEY,"content-type": "application/json",}

        this.authUser= (await axios.post("https://auth.documedias.com/api/user/getorcreate", this.newConsultant, {headers:headers})).data

        //maps user into consultants table
        this.newConsultantMap.user_id = this.authUser.id;
        this.newConsultantMap.exhibitor_id = this.userExhibitor[0].id;
        this.newConsultantMap.firstname = this.newConsultant.firstName;
        this.newConsultantMap.lastname = this.newConsultant.lastName;
        this.newConsultantMap.email = this.newConsultant.emailBusiness;
        this.newConsultantMap.available_from = "09:00"
        this.newConsultantMap.available_until = "17:00"
        this.newConsultantMap.hour_duration = 0
        this.newConsultantMap.minute_duration = 30
        this.newConsultantMap.disabled = false //TEST
        await axios.post(API_URL+"consultants/", this.newConsultantMap)
        this.refreshAppointments();
        await this.getConsultantsByExhibitor(this.userExhibitor[0].id)
        }
      }
  },

  },

	async created(){
    if(localStorage.getItem('x-api-key') != ''){
      let userRequest = await this.$store.dispatch("auth/GET_USERDATA");
      this.user = userRequest.data


    this.exhibitorsArray =  await this.getAllExhibitors()
    //this.exhibitorsArray= (await axios.get("https://mantel-api.mobile.documedias.systems/v2/conferences/"+this.conferenceId+"/exhibitors")).data //get all exs of conference

    this.userExhibitor = this.exhibitorsArray.filter(ex=>ex.email == this.user.emailBusiness) 

    await this.getConsultantsByExhibitor(this.userExhibitor[0].id)
    this.consultantsIdArray = this.consultantsByExhibitor.map(a => a.id);
    this.appointmentsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.conferenceId, 'Accepted': true }; 
    this.requestsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.conferenceId}; 

    this.desktop= isPlatform('desktop')

    this.exhibitorsArray =  await this.getAllExhibitors()
    //this.exhibitorsArray= (await axios.get("https://mantel-api.mobile.documedias.systems/v2/conferences/"+this.conferenceId+"/exhibitors")).data //get all exs of conference
    this.userExhibitor = this.exhibitorsArray.filter(ex=>ex.email == this.user.emailBusiness) 
    await this.getConsultantsByExhibitor(this.userExhibitor[0].id)
    }
   
  },

});
</script>
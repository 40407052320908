<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Appointment Request
      </ion-title>
      <ion-buttons slot="end">
                <ion-button @click="closeModal()"> <ion-icon class="request-icon" :icon="closeOutline"></ion-icon></ion-button>
              </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
     <ion-grid >
        <ion-row class="modal-request-table-title"  >
            <ion-col>
              <div>{{requestSent.subject}}</div>
            </ion-col>
            <ion-col>
              <div>{{requestSent.description}}</div>
            </ion-col>
         </ion-row>
          
         <ion-row>
            <ion-col class="modal-request-titles">
              <div >Consultant: </div>
            </ion-col>
            <ion-col class="modal-request-text" >
              <div> {{requestSent.consultant.firstname}} </div>
            </ion-col>
           <ion-col class="modal-request-text" >
              <div> {{requestSent.consultant.lastname}}</div>
            </ion-col>
            <ion-col class="modal-request-text" >
              <div>  </div>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col class="modal-request-titles">
              <div >Date: </div>
            </ion-col>
            <ion-col class="modal-request-text" >
              <div>{{getFormattedRequestDate(requestSent.start_at).day}} </div>
            </ion-col>
           <ion-col class="modal-request-text" >
              <div></div>
            </ion-col>
            <ion-col class="modal-request-text" >
              <div>  </div>
            </ion-col>
        </ion-row>
        <ion-row>
           <ion-col class="modal-request-titles">
              <div > From: </div>
            </ion-col>
            <ion-col class="modal-request-text">
              <div> {{getFormattedRequestDate(requestSent.start_at).hour}} </div>
            </ion-col>
            <ion-col class="modal-request-titles">
              <div > Until: </div>
            </ion-col>
            <ion-col class="modal-request-text">
              <div> {{getFormattedRequestDate(requestSent.end_at).hour}}</div>
            </ion-col>
        </ion-row>

        <ion-row style="margin-top: 20px;">
          <ion-col >
                 <button v-if="slotFree" class="requests-buttons requests-accept-button"  @click="acceptRequest()">
                     Accept <ion-icon class="request-icon" :icon="checkmarkOutline"></ion-icon>
                </button>
                <button v-if="slotFree==false" class="busy-buttons">
                  There's already an Appointment booked at this hour.
                </button>
            </ion-col>
            <ion-col>
               <button class="requests-buttons"  @click="rejectRequest()">
                     Reject <ion-icon class="request-icon" :icon="closeOutline"></ion-icon>
                </button>
            </ion-col>
        </ion-row>

      </ion-grid>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import {checkmarkOutline, closeOutline} from 'ionicons/icons';
const config = require('@/config');
const API_URL = config.appointment_api_url;
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment'

export default defineComponent({
  name: "RequestModal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar },
  props: ['requestSent'],
  data(){
    return{
    conferenceId : config.conference,
    consultantsIdArray: [],
    appointmentsInfo : {},
    requestsInfo : {},
    slotFree : null,
    }
  },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };

    return { checkmarkOutline, closeOutline, closeModal };
  },
  computed: {
    ...mapGetters('appointment',['appointmentsByRequester', 'appointments', 'appointmentRequests']),
 ...mapGetters('consultant',['consultantsByExhibitor']),
 },

  methods: {
    ...mapActions('consultant',['getConsultantsByExhibitor']),
    ...mapActions('appointment',['getAppointments', 'getRequests']),
    ...mapActions('exhibitors', ['getAllExhibitors']),
    
      getFormattedRequestDate(date){
                let fullhour = {};
                fullhour.day = date.slice(0, 10)
                fullhour.hour = date.slice(10, 16)
                const regEx = new RegExp('-', "g");
                fullhour.day = fullhour.day.replace(regEx, '/')
                return fullhour;
             },

      checkIfBusy(request){
        let startTime = moment(request.start_at).utc(false).toISOString()
        let endTime = moment(request.end_at).utc(false).toISOString()
        let busyHours = [];

     

        for (let i=0; i<this.appointments.length; i++){
            busyHours.push({start : moment(this.appointments[i].start_at).utc(false).toISOString(), end : moment(this.appointments[i].end_at).utc(false).toISOString() });
        
        }
       
        let counter = 0;

         for (let i=0; i<busyHours.length; i++){ 
        

           if(moment(startTime).isBefore(busyHours[i].start) && moment(endTime).isAfter(busyHours[i].end)){
            counter++
           }

           if(moment(startTime).isSame(moment(busyHours[i].start), 'minute')){
               counter++
            }

            if(moment(endTime).isSame(moment(busyHours[i].end), 'minute')){
               counter++
            }

          if(moment(startTime).isBetween(moment(busyHours[i].start, busyHours[i].end).utc(false).toISOString(), 'minute') || moment(endTime).isBetween(moment(busyHours[i].start, busyHours[i].end).utc(false).toISOString(), 'minute')){
               counter++;
            } 
            
            
         } 

          if(counter == 0){
            this.slotFree = true
          } else {
            this.slotFree = false;
          }
          console.log(counter)
      },


      async acceptRequest(){

      let id = this.requestSent.id
      let updateAppointment = {}
      updateAppointment.accepted=true;
      await axios.put(API_URL+"appointments/"+id, updateAppointment);
      await axios.post(API_URL+"acceptedMail/", this.requestSent);

      await this.getAppointments(this.appointmentsInfo)
      await this.getRequests(this.requestsInfo)
      
      this.closeModal();
      },

      async rejectRequest(){
      let id = this.requestSent.id;
      let updateAppointment = {}
      updateAppointment.rejected=true;
      await axios.put(API_URL+"appointments/"+id, updateAppointment);
      await axios.post(API_URL+"rejectedMail/", this.requestSent);

      await this.getAppointments(this.appointmentsInfo)
      await this.getRequests(this.requestsInfo)
      
      this.closeModal();
      },
  },

	async created(){
    if(localStorage.getItem('x-api-key') != ''){
      let userRequest = await this.$store.dispatch("auth/GET_USERDATA");
      this.user = userRequest.data

    this.exhibitorsArray =  await this.getAllExhibitors()
    //this.exhibitorsArray= (await axios.get("https://mantel-api.mobile.documedias.systems/v2/conferences/"+this.conferenceId+"/exhibitors")).data //get all exs of conference
    this.userExhibitor = this.exhibitorsArray.filter(ex=>ex.email == this.user.emailBusiness)  //CHANGE THIS AFTER TESTING
    await this.getConsultantsByExhibitor(this.userExhibitor[0].id)
    this.consultantsIdArray = this.consultantsByExhibitor.map(a => a.id);
    this.appointmentsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.conferenceId, 'Accepted': true }; 
    this.requestsInfo = {'ConsultantArray': this.consultantsIdArray, 'Conference': this.conferenceId}; 

    this.checkIfBusy(this.requestSent);
    }
 },

});
</script>